<template>
  <main>
    <form @submit.prevent="submit" class="flex flex-col gap-4" action="">
      <easiTextInput v-model="args.bvn" placeholder="Enter BVN"></easiTextInput>

      <div class="justify-center mb-3">
        <UploadComponent
          label="Upload passport photo"
          :listInfo="[
            'Forward-facing headshot with plain background',
            'In colour',
            'Image should not be blurred or include filters',
            'Taken within the last 6 months',
            'With a neutral face expression with both eyes open',
            'Not be more than 2MB',
            'Jpeg, jpg or png allowed',
          ]"
          :dottedNew="true"
          @fileUrl="uploadFile = $event"
        />
      </div>

      <div class="w-full flex justify-center">
        <easiButton :loading="loading" block> <p class="w-40 text-sm">Submit</p></easiButton>
      </div>
    </form>
  </main>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import { ref, reactive } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const emit = defineEmits(["saved"]);
const { uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { mutate } = store;

const loading = ref(false);
const uploadFile = ref(null);
const args = reactive({
  bvn: "",
  birthInformation: {
    passportPhoto: null,
  },
});

function validate() {
  if (uploadFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}

const createPersonalInformation = async (task) => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.birthInformation.passportPhoto = await uploadFileToServer(uploadFile.value);
    }
    if (typeof args.birthInformation.passportPhoto !== "string") {
      toast.error("Upload failed");
      return;
    }
    let res = await mutate({
      endpoint: "CreatePersonalInformation",
      data: { input: { birthInformation: { passportPhoto: args.birthInformation.passportPhoto } } },
      service: "SETTINGS",
    });

    if (res && res.success) {
      // emit("saved", true);
    } else if (res && !res.success) {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
};
const createAccountInformation = async () => {
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "CreateAccountInformation",
      data: { input: { bvn: args.bvn } },
      service: "SETTINGS",
    });
    if (res && res.success) {
      // emit("saved");
    } else if (res && !res.success) {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
};

async function upgradeTier() {
  const res = await mutate({
    endpoint: "UpgradeTiers",
    data: { tier: "1" },
    service: "SETTINGS",
  });
  if (res && res.success) {
    toast.success("Tier upgraded successfully");
    emit("saved");
  } else if (res && !res.success) {
    toast.error(res.message);
  }
}
async function submit() {
  try {
    if (validate()) {
      await createPersonalInformation();
      await createAccountInformation();
      await upgradeTier();
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
