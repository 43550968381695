<template>
  <main>
    <div class="flex flex-col gap-6 items-center justify-center">
      <img src="@/assets/img/easi-success.gif" alt="" />

      <h2 class="font-bold text-primary-deep text-xl mt-6">
        Submitted successfully
      </h2>

      <h4 class="text-sm text-dark-800 text-center">
        Your details have been submitted and will be upgraded when confirmed
      </h4>

      <button
        @click="emit('dismiss')"
        class="focus:outline-none rounded-full py-2 px-3 bg-dark-150 text-dark-800 text-sm w-40 flex justify-center self-center cursor-pointer"
      >
        Dismiss
      </button>
    </div>
  </main>
</template>

<script setup>
const emit = defineEmits(["dismiss"]);
</script>

<style></style>
