<template>
  <main>
    <div class="flex items-center justify-between">
      <span class="text-dark-800 font-bold text-sm">Liveliness test</span>
      <GetStatus v-if="kycInfo" status="verified" />
    </div>
    <form @submit.prevent="submit" class="flex flex-col mt-6 gap-4" action="">
      <div class="text-sm">
        Record a 10 seconds video of yourself saying your name, why you’re opening an Eazipay account and where you
        live.. e.g
        <span class="text-blue-500">
          “ hello my name is ….. , i am opening an Eazipay account today  …. I live at …….)</span
        >
      </div>
      <div class="text-sm">
        <span class="text-error font-bold leading-6">Important Notice</span>
        <p>
          To verify, record a well-lit, in-focus video of your face. Accepted formats: AVI, MP4, WEBM, up to 20MB.
          Record within the highlighted area and re-upload if necessary.
        </p>
      </div>
      <div class="w-full">
        <VideoRec @video-recorded="($event) => assignFile($event)" />

        <!-- <qoreid-button
          id="QoreIDButton"
          clientId="BLYXWG67VASLP9EB8ZFU"
          productCode="liveness"
          :applicantData="JSON.stringify(companyAdmin.companyAdmin)"
          :customerReference="companyAdmin.companyAdmin._id"
        ></qoreid-button> -->
      </div>
      <!-- <div  class="py-2 px-4 border border-dark-150 shadow-sm rounded-xl flex items-center justify-between">
        <div class="flex items-center gap-2">
          <div class="h-12 w-12 flex justify-center items-center bg-dark-550 rounded-full flex-shrink-0">
            <img src="@/assets/icons/img-icon.svg" alt="" />
          </div>
          <div class="flex flex-col">
            <span class="text-xs">My Verification video (.mov) </span>
          </div>
        </div>

        <GetStatus status="view" />
      </div> -->

      <hr class="border-0.5 border-dark-150 mt-2" />

      <div v-if="!kycInfo" class="flex justify-center">
        <easiButton :loading="loading"><p class="w-40">Submit</p></easiButton>
      </div>
      <button
        v-else
        type="button"
        @click="emit('dismiss')"
        class="focus:outline-none rounded-full py-2 px-3 bg-dark-150 text-dark-800 text-sm w-40 flex justify-center self-center cursor-pointer"
      >
        Dismiss
      </button>
    </form>
  </main>
</template>

<script setup>
// import UploadComponent from "@/components/global/UploadComponent";
import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";
import VideoRec from "@/components/global/VideoRecorder.vue";
const { uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { mutate } = store;
import { useToast } from "vue-toastification";

import { ref, computed } from "vue";
import GetStatus from "../GetStatus.vue";

const toast = useToast();
const emit = defineEmits(["dismiss", "saved"]);

const companyAdmin = computed(() => store.getCompanyAdmin);

const uploadFile = ref(null);
const loading = ref(false);
function assignFile(fil) {
  uploadFile.value = fil;
}

const args = ref({
  idType: "video_verification",
  front: "",
  back: "",
});
const kycInfo = computed(
  () => false
  // store.getKyc &&
  // store.getKyc.data &&
  // store.getKyc.data.identityInformation &&
  // store.getKyc.data.identityInformation.find(
  //   (info) => info.idType === "video_verification"
  // )
);
function validate() {
  if (uploadFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}

const createValidIdentityInformation = async () => {
  loading.value = true;

  try {
    if (uploadFile.value) {
      args.value.front = await uploadFileToServer(uploadFile.value);
    }
    if (typeof args.value.front !== "string") {
      toast.error("Upload failed");
      return;
    }
    const data = [args.value];
    let res = await mutate({
      endpoint: "CreateValidIdentityInformation",
      data: { input: data },
      service: "SETTINGS",
    });
    if (res.success) {
      emit("saved", true);
    } else {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
};

async function upgradeTier() {
  const res = await mutate({
    endpoint: "UpgradeTiers",
    data: { tier: "3" },
    service: "SETTINGS",
  });
  if (res && res.success) {
    toast.success("Tier upgraded successfully");
  } else if (res && !res.success) {
    toast.error(res.message);
  }
}

async function submit() {
  try {
    if (validate()) {
      await createValidIdentityInformation();
      await upgradeTier();
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
