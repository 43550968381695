<template>
  <div
    :style="[
      getStatus && getStatus.color,
      bgWhite ? `background-color:white!important` : '',
    ]"
    class="flex items-center capitalize gap-2 p-2 md:px-3 font-semibold rounded-full text-xs"
  >
    <img class="hidden md:block" :src="getStatus && getStatus.icon" alt="" />
    {{ status }}
  </div>
</template>

<script setup>
import verified from "@/assets/icons/verified.svg";
import pending from "@/assets/icons/statusPending.svg";
import notVerified from "@/assets/icons/statusNotVerified.svg";
import { computed } from "vue";

const props = defineProps({
  status: {
    type: String,
    default: "",
  },
  bgWhite: {
    type: Boolean,
    default: false,
  },
});

const getStatus = computed(() => {
  const color = {
    pending: {
      color: `background-color:#F59E0B26; color:#F59E0B`,
      icon: pending,
    },
    verified: {
      color: `background-color:#2196531A; color:#219653`,
      icon: verified,
    },
    view: {
      color: `background-color:#F3F3F3; color:#11453B`,
      icon: null,
    },
    "not verified": {
      color: `background-color:#EF44441A; color:#EF4444`,
      icon: notVerified,
    },
  };

  return props.status
    ? color[props.status.toLowerCase()]
    : { color: "", icon: "" };
});
</script>

<style></style>
