<template>
  <main>
    <div class="flex items-center justify-between">
      <span class="text-dark-800 font-bold text-sm">Indemnity form</span>
    </div>

    <div class="flex items-center mt-4 justify-between">
      <span style="color: #888888" class="pr-3 font-medium text-xs"
        >Kindly download and sign the Eazipay
        <span @click="preview = !preview" class="cursor-pointer text-info-light">Indemnity form</span></span
      >

      <div
        @click="fetchIndemnity"
        class="rounded-lg py-2 px-3 bg-dark-150 text-dark-800 text-sm w-40 flex justify-center self-center cursor-pointer"
      >
        <svg
          class="inline-block"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0581 18.8853C10.3021 19.1294 10.6979 19.1294 10.9419 18.8853L14.6919 15.1353C14.936 14.8912 14.936 14.4955 14.6919 14.2514C14.4479 14.0073 14.0521 14.0073 13.8081 14.2514L11.125 16.9345L11.125 7.19336C11.125 6.84818 10.8452 6.56836 10.5 6.56836C10.1548 6.56836 9.875 6.84818 9.875 7.19336L9.875 16.9345L7.19194 14.2514C6.94786 14.0073 6.55214 14.0073 6.30806 14.2514C6.06398 14.4955 6.06398 14.8912 6.30806 15.1353L10.0581 18.8853ZM3 7.81836C3.34518 7.81836 3.625 7.53854 3.625 7.19336V5.31836C3.625 4.628 4.18464 4.06836 4.875 4.06836H16.125C16.8154 4.06836 17.375 4.628 17.375 5.31836V7.19336C17.375 7.53854 17.6548 7.81836 18 7.81836C18.3452 7.81836 18.625 7.53854 18.625 7.19336V5.31836C18.625 3.93765 17.5057 2.81836 16.125 2.81836H4.875C3.49429 2.81836 2.375 3.93765 2.375 5.31836V7.19336C2.375 7.53854 2.65482 7.81836 3 7.81836Z"
            fill="#515251"
          />
        </svg>

        <span class="ml-2">Download</span>
      </div>
    </div>
    <hr class="border-0.5 border-dark-150 mt-6" />

    <form @submit.prevent="submitIndemnity" class="flex flex-col gap-4" action="">
      <div class="justify-center mt-5">
        <UploadComponent :dottedNew="true" @fileUrl="uploadFile = $event" />
      </div>

      <div class="w-full flex justify-center">
        <easiButton type="submit" block> <p class="w-40 text-sm">Submit</p></easiButton>
      </div>
    </form>

    <easiModal :terms="true" :isBorder="false" rounded="2xl" v-show="preview" @close="preview = false">
      <div
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
        style="overflow: auto; height: calc(100vh - 250px)"
      >
        <!-- <iframe
          src="https://eazipay.storage.googleapis.com/e25cbd6d-abe4-4caa-8b57-a43b64b3c5a3-indemnity-form-(2).pdf"
          style="width: 98%; height: 100%"
        >
        </iframe> -->
        <!-- <iframe :srcdoc="htmlContent" style="width: 98%; height: 100%">
        </iframe> -->
        <div class="w-full overflow-x-hidden">
          <htmlContent />
        </div>
      </div>
    </easiModal>

    <easiLoading v-if="loading" />
  </main>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import HtmlContent from "./Indemnity.vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { uploadFileToServer, downloadBase64 } = helperFunctions;

const toast = useToast();
const store = useDataStore();

const { mutate, query } = store;
const props = defineProps({
  amount: { type: Number, default: 0 },
});
const preview = ref(false);

const uploadFile = ref(null);
const emit = defineEmits(["saved"]);

const companyDetails = computed(() => store.getCompanyDetails);

const typeOfCompany = computed(() => companyDetails.value && companyDetails.value.registrationType);
const loading = ref(false);

async function fetchIndemnity() {
  loading.value = true;
  try {
    const res = await query({
      endpoint: "GetIndemity",
      service: "SETTINGS",
      payload: {
        input: {
          transferLimit: props.amount,
        },
      },

      // storeKey: "companyAdmin",
    });
    if (res && res.success) {
      await downloadBase64(res.data.base64, res.data.fileName);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}
async function upgradeTier() {
  const res = await mutate({
    endpoint: "UpgradeTiers",
    data: { tier: typeOfCompany.value === "PERSONAL" ? "4" : "5" },
    service: "SETTINGS",
  });
  if (res && res.success) {
    toast.success("Tier upgraded successfully");
    emit("saved", true);
  } else if (res && !res.success) {
    toast.error(res.message);
  }
}

function validate() {
  if (uploadFile.value) {
    return true;
  }
  toast.error("Upload all files");
  return false;
}
async function submitIndemnity() {
  loading.value = true;
  try {
    if (validate()) {
      const res = await uploadFileToServer(uploadFile.value, { input: { transferLimit: 20000 } });

      if (res && res.success) {
        await upgradeTier();
      } else if (res && !res.success) {
        toast.error(res.message);
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
}
</script>

<style></style>
