<template>
  <main>
    <span class="font-medium text-dark-800 text-sm">Registration details</span>
    <div class="mt-2 flex items-center justify-between">
      <span class="font-medium text-dark-200 text-xs">Is your business registered?</span>
      <div
        class="flex cursor-pointer w-24 items-center gap-x-4 rounded-lg py-1 border border-outlineGray px-1 bg-newAsh text-newGray font-bold"
      >
        <span
          @click="args.isCompanyRegistered = true"
          class="text-xs px-1 w-full"
          :class="args.isCompanyRegistered === true ? 'bg-white  p-1  rounded-md' : undefined"
          >YES</span
        >
        <span
          @click="args.isCompanyRegistered = false"
          class="text-xs px-1 w-full"
          :class="args.isCompanyRegistered === false ? 'bg-white  p-1 rounded-md' : undefined"
          >NO</span
        >
      </div>
    </div>
    <form v-show="args.isCompanyRegistered" @submit.prevent="submit" class="flex flex-col" action="">
      <easiTextInput required placeholder="Name of registered business"></easiTextInput>
      <easiTextInput required placeholder="CAC registration number" v-model="args.cacNumber"></easiTextInput>

      <div class="flex gap-2 my-5 items-center">
        <easiSelectInput2
          :options="companyType"
          @update="args.businessType = $event"
          :value="args.businessType"
          placeholder="Select business type"
          class="w-full"
        ></easiSelectInput2>
      </div>
      <div v-for="form in matchTypeToForm" :key="form.docKey" class="justify-center my-2">
        <UploadComponent :label="form.label" :dottedNew="true" @fileUrl="form.uploadFile = $event" />
      </div>

      <div class="w-full my-4 flex justify-center">
        <easiButton :disabled="args.isCompanyRegistered === false" :loading="loading" block>
          <p class="w-40 text-sm">Submit</p></easiButton
        >
      </div>
    </form>

    <div v-show="args.isCompanyRegistered === false" class="w-full mt-16 flex justify-center">
      <easiButton :disabled="true" block> <p class="w-40 text-sm">Submit</p></easiButton>
    </div>
  </main>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import { ref, computed, reactive } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const toast = useToast();
const emit = defineEmits(["saved"]);
const { uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { mutate } = store;

const loading = ref(false);
// const uploadFile = ref(null);
// const addressFile = ref(null);
const args = reactive({
  isCompanyRegistered: false,
  cacRegistration: null,
  businessType: null,
  cacNumber: "",
});
const companyType = ref([
  {
    label: "Limited Liability Company (Ltd)",
    value: "limited_liability_company",
  },
  {
    label: "Non-Governmental Organization (NGO)",
    value: "non_governmental_organization",
  },

  {
    label: "Public Limited Company (PLC)",
    value: "public_limited_company",
  },
  {
    label: "Government parastatal",
    value: "government",
  },
  {
    label: "Sole Proprietorship",
    value: "sole_proprietorship",
  },
]);

const formFileLtd = ref([
  {
    label: "Upload CAC Document",
    uploadFile: null,
    docKey: "cac_document",
    status: null,
  },
  {
    label: "6 month bank statement",
    uploadFile: null,
    docKey: "six_months_bank_statement",
    status: null,
  },
  {
    label: "Form C02",
    uploadFile: null,
    docKey: "form_c02",
    status: null,
  },
  //   {
  //     label: "Form C07",
  //     uploadFile: null,
  //     docKey: "form_c07",
  //     status: null,
  //   },
  //   { label: "MEMART", uploadFile: null, docKey: "memart", status: null },
]);

const formFileNgo = ref([
  {
    label: "Corporate Affairs Commission (CAC Certification)",
    uploadFile: null,
    docKey: "cac_document",
    status: null,
  },
  {
    label: "NGO Constitution",
    uploadFile: null,
    docKey: "ngoConstitution",
    status: null,
  },
  {
    label: "Upload letter of Organization’s objectives and purpose",
    uploadFile: null,
    docKey: "objectivePurpose",
    status: null,
  },
  //   {
  //     label: "Upload proof of office address e.g. IKEDC, Lawma, etc. (.png, jpg)",
  //     uploadFile: null,
  //     docKey: "proofOfAddress",
  //     docType: "img",
  //     status: null,
  //   },

  //   {
  //     label: "6 month bank statement",
  //     uploadFile: null,
  //     docKey: "six_months_bank_statement",
  //     status: null,
  //   },
  //   {
  //     label: "Incorporated Trustee document",
  //     uploadFile: null,
  //     docKey: "trusteeDocument",
  //     status: null,
  //   },
]);

const formFilePlc = ref([
  {
    label: "Upload CAC Document",
    uploadFile: null,
    docKey: "cac_document",
    status: null,
  },
  {
    label: "Upload Tax Clearance certificate",
    uploadFile: null,
    docKey: "taxClearance",
    status: null,
  },
  { label: "MEMART", uploadFile: null, docKey: "memart", status: null },
]);

const formFileGovt = ref([
  {
    label: "Upload Act of parliament certificate",
    uploadFile: null,
    docKey: "parliament",
    status: null,
  },
]);
const formFileSole = ref([
  {
    label: "Upload CAC Document",
    uploadFile: null,
    docKey: "cacDocument",
    status: null,
  },
]);

const matchTypeToForm = computed(() => {
  switch (args.businessType) {
    case "limited_liability_company":
      return formFileLtd.value;
    case "non_governmental_organization":
      return formFileNgo.value;
    case "public_limited_company":
      return formFilePlc.value;
    case "government":
      return formFileGovt.value;
    case "sole_proprietorship":
      return formFileSole.value;
    default:
      return [];
  }
});

function validate() {
  const nullVal = matchTypeToForm.value.find((fil) => fil.uploadFile == null);
  if (nullVal) {
    toast.warning("Upload all documents to continue");
    return false;
  }
  return true;
}
async function processItemsWithAsyncFunction(items) {
  let payload = [];
  for (const item of items) {
    try {
      if (item.uploadFile && item.docKey) {
        payload.push({
          name: item.docKey,
          document: typeof item.uploadFile == "string" ? item.uploadFile : await uploadFileToServer(item.uploadFile),
        });
      }
    } catch (error) {
      console.error("Error processing item:", error);
      throw new Error(error);
    }
  }
  return payload;
}

async function submitCompanyInfo() {
  loading.value = true;
  try {
    //   if (uploadFile.value) {
    //     args.cacRegistration = await uploadFileToServer(uploadFile.value);
    //   }
    //   if (typeof args.cacRegistration !== "string") {
    //     toast.error("File upload failed");
    //     return;
    //   }
    let res = await mutate({
      endpoint: "CreateCompanyInformation",
      service: "SETTINGS",
      data: {
        input: args,
      },
    });
    if (res && res.success) {
      toast.success(res.message);
      emit("saved");
    } else if (res && !res.success) {
      toast.error(res.message);
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
}
async function submitDocs() {
  loading.value = true;
  try {
    let data = await processItemsWithAsyncFunction(matchTypeToForm.value);
    //   const document = await uploadFileToServer(directorsFile.value);
    //   args.value = [...data, { name: "companyDirectors", document }];
    const payload = [...data];
    let res = await mutate({
      endpoint: "CreateCompanyDocuments",
      service: "SETTINGS",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      // emit("saved");
    } else {
      throw new Error(res.message);
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  } finally {
    loading.value = false;
  }
}
async function upgradeTier() {
  const res = await mutate({
    endpoint: "UpgradeTiers",
    data: { tier: "4" },
    service: "SETTINGS",
  });
  if (res && res.success) {
    emit("saved");
  } else if (res && !res.success) {
    toast.error(res.message);
  }
}
async function submit() {
  try {
    if (validate()) {
      await submitCompanyInfo();
      await submitDocs();
      await upgradeTier();
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
