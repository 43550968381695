<template>
  <main class="flex flex-col gap-5 mt-8">
    <div
      v-for="tier in tierData"
      :key="tier.name"
      :class="!tier.active ? 'border border-dark-150' : 'shadow-md'"
      style="border-radius: 24px"
    >
      <div
        style="border-top-right-radius: 24px; border-top-left-radius: 24px"
        :class="tier.active ? 'bg-primary' : 'bg-dark-550'"
        class="px-4 py-2 flex items-center justify-between w-full"
      >
        <h3 :class="tier.active ? 'text-white' : 'text-dark-850'" class="font-bold text-sm capitalize">
          {{ tier.name }}
        </h3>

        <div :class="tier.active ? 'bg-primary-light' : 'bg-white'" class="rounded-xl p-2">
          <svg
            v-if="tier.active"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.50065 2.27734C6.75398 2.27734 5.33398 3.69734 5.33398 5.44401C5.33398 7.15734 6.67398 8.54401 8.42065 8.60401C8.47398 8.59734 8.52732 8.59734 8.56732 8.60401C8.58065 8.60401 8.58732 8.60401 8.60065 8.60401C8.60732 8.60401 8.60732 8.60401 8.61398 8.60401C10.3207 8.54401 11.6607 7.15734 11.6673 5.44401C11.6673 3.69734 10.2473 2.27734 8.50065 2.27734Z"
              fill="white"
            />
            <path
              d="M11.8866 10.3831C10.0266 9.14312 6.99331 9.14312 5.11997 10.3831C4.27331 10.9431 3.80664 11.7098 3.80664 12.5298C3.80664 13.3498 4.27331 14.1098 5.11331 14.6698C6.04664 15.2965 7.27331 15.6098 8.49997 15.6098C9.72664 15.6098 10.9533 15.2965 11.8866 14.6698C12.7266 14.1031 13.1933 13.3431 13.1933 12.5165C13.1866 11.7031 12.7266 10.9365 11.8866 10.3831ZM10.0533 11.9831L8.37331 13.6631C8.29331 13.7431 8.18664 13.7831 8.07997 13.7831C7.97331 13.7831 7.86664 13.7365 7.78664 13.6631L6.94664 12.8231C6.78664 12.6631 6.78664 12.3965 6.94664 12.2365C7.10664 12.0765 7.37331 12.0765 7.53331 12.2365L8.07997 12.7831L9.46664 11.3965C9.62664 11.2365 9.89331 11.2365 10.0533 11.3965C10.22 11.5565 10.22 11.8231 10.0533 11.9831Z"
              fill="white"
            />
          </svg>
          <svg v-else width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.73268 8.68677C8.71268 8.68677 8.69935 8.68677 8.67935 8.68677C8.64602 8.6801 8.59935 8.6801 8.55935 8.68677C6.62602 8.62677 5.16602 7.10677 5.16602 5.23344C5.16602 3.32677 6.71935 1.77344 8.62602 1.77344C10.5327 1.77344 12.086 3.32677 12.086 5.23344C12.0793 7.10677 10.6127 8.62677 8.75268 8.68677C8.74602 8.68677 8.73935 8.68677 8.73268 8.68677ZM8.62602 2.77344C7.27268 2.77344 6.16602 3.8801 6.16602 5.23344C6.16602 6.56677 7.20602 7.6401 8.53268 7.68677C8.57268 7.6801 8.65935 7.6801 8.74602 7.68677C10.0527 7.62677 11.0793 6.55344 11.086 5.23344C11.086 3.8801 9.97935 2.77344 8.62602 2.77344Z"
              fill="#B9B9B9"
            />
            <path
              d="M8.68143 15.976C7.37477 15.976 6.06143 15.6427 5.0681 14.976C4.14143 14.3627 3.63477 13.5227 3.63477 12.6094C3.63477 11.696 4.14143 10.8494 5.0681 10.2294C7.0681 8.90271 10.3081 8.90271 12.2948 10.2294C13.2148 10.8427 13.7281 11.6827 13.7281 12.596C13.7281 13.5094 13.2214 14.356 12.2948 14.976C11.2948 15.6427 9.9881 15.976 8.68143 15.976ZM5.62143 11.0694C4.98143 11.496 4.63477 12.0427 4.63477 12.616C4.63477 13.1827 4.9881 13.7294 5.62143 14.1494C7.28143 15.2627 10.0814 15.2627 11.7414 14.1494C12.3814 13.7227 12.7281 13.176 12.7281 12.6027C12.7281 12.036 12.3748 11.4894 11.7414 11.0694C10.0814 9.96271 7.28143 9.96271 5.62143 11.0694Z"
              fill="#B9B9B9"
            />
          </svg>
        </div>
      </div>
      <div
        style="border-bottom-left-radius: 24px; border-bottom-right-radius: 24px"
        :class="tier.active ? 'bg-mint' : 'bg-white'"
        class="flex gap-3 px-4 w-full py-2 flex-col"
      >
        <h4 class="text-xs font-medium text-dark-850">Benefits</h4>

        <div v-if="typeof tier.benefits === 'string'" class="w-full text-sm text-dark-800">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="w-1.5 h-1.5 rounded-full bg-dark-800"></div>
              <span class="text-xs">{{ tier.benefits }}</span>
            </div>
          </div>
        </div>

        <div v-else class="flex flex-col gap-3 w-full text-xs text-dark-800">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="w-1.5 h-1.5 rounded-full bg-dark-800"></div>
              <span class="text-xs"> Daily transfer limit</span>
            </div>
            <span class="text-right font-bold text-xs text-right">{{ formatAmount(tier.benefits.daily) }}</span>
          </div>

          <div v-if="tier.benefits && tier.benefits.monthly" class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="w-1.5 h-1.5 rounded-full bg-dark-800"></div>
              <span class="text-xs"> Monthly transfer limit</span>
            </div>
            <span class="text-right font-bold text-xs text-right">{{ formatAmount(tier.benefits.monthly) }}</span>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <div class="w-1.5 h-1.5 rounded-full bg-dark-800"></div>
              <span class="text-xs">Unlimited deposits</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;
const store = useDataStore();

const active = ref(true);

const companyDetails = computed(() => store.getCompanyDetails);

const tierData = computed(() => {
  let list = [];
  let arr = [
    {
      name: "Tier 0",
      benefits: "Deposits, but no withdrawals",
      active: companyDetails.value && companyDetails.value.tier === "0",
    },
    {
      name: "Tier 1",
      benefits: { daily: 100000, monthly: 1000000 },
      active: companyDetails.value && companyDetails.value.tier === "1",
    },
    {
      name: "Tier 2",
      benefits: { daily: 1000000, monthly: 5000000 },
      active: companyDetails.value && companyDetails.value.tier === "2",
    },
    {
      name: "Tier 3",
      benefits: { daily: 5000000, monthly: 10000000 },
      active: companyDetails.value && companyDetails.value.tier === "3",
    },
  ];

  arr.forEach((el) => {
    if (
      companyDetails.value &&
      companyDetails.value.registrationType === "COMPANY" &&
      companyDetails.value.tier === "4"
    ) {
      list.push(el);
    } else if (el.name !== "Tier 4") {
      list.push(el);
    }
  });

  if (companyDetails.value && companyDetails.value.registrationType === "COMPANY") {
    list.push({
      name: "Tier 4",
      benefits: { daily: 10000000, monthly: null },
      active: companyDetails.value && companyDetails.value.tier === "4",
    });
  }

  return list;
});
</script>

<style></style>
